import React, { Component } from "react";
import styled from "styled-components";
import device from "../../../Assets/Responsive/breakpoints";

const ModContainer = styled.section`
  height: 50vh; /* Since pageSplitTime is 1.4 */
  width: 100%;
  /* border: 1px solid blue; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const MobAboutMeDescription = styled.span`
  font-family: "AvenirRoman";
  font-size: 24px;
  text-align: center;
  @media ${device.mobileS} {
    padding: 30px;
    font-size: 20px;
  }
  @media ${device.mobileM} {
    padding: 30px;
    font-size: 23px;
  }
  @media ${device.mobileL} {
    padding: 30px;
    font-size: 24px;
  }
  @media ${device.tablet} {
    padding: 80px;
    font-size: 40px;
  }
  @media ${device.laptop} {
    padding: 90px;
    font-size: 45px;
  }
`;

class MobAboutMe extends Component {
  render() {
    return (
      <ModContainer>
        <MobAboutMeDescription>
          A passionate problem solver and software developer who loves creating
          projects that tackle real-world challenges. With a solid foundation in
          Java and a proven ability to quickly learn and adapt to new
          technologies, I thrive on building scalable and impactful solutions. I
          specialize in Full Stack Web Development and Mobile App Development,
          with expertise in Java, Flutter and MERN stack. My strong
          understanding of CS fundamentals and proficiency in object-oriented
          programming enable me to bridge the gap between frontend and backend
          development seamlessly.Beyond coding, I am deeply motivated by the
          opportunity to solve complex problems and deliver innovative,
          user-centric solutions. I am a fast learner who keeps pace with the
          latest industry trends, constantly striving to enhance my skills and
          make a meaningful impact through technology.
        </MobAboutMeDescription>
      </ModContainer>
    );
  }
}

export default MobAboutMe;
