import React, { Component } from "react";
import styled from "styled-components";
import device from "../../../Assets/Responsive/breakpoints";

const Container = styled.section`
  height: 90vh; /* Since pageSplitTime is 1.4 */
  width: 100%;
  // border: 1px solid blue;
  position: relative;
  overflow: hidden;
`;

const AboutMeTitle = styled.div.attrs({
  style: ({ scrollPercent }) => ({
    transform: `translateX(${scrollPercent * 5.5}%)`,
  }),
})`
  transition: transform 0.5s ease-out;
  font-family: "AvenirHeavy";
  position: absolute;
  color: #eee;
  top: 5%;
  left: -15%;
  @media ${device.laptop} {
    font-size: 170px;
  }
  @media ${device.laptopL} {
    font-size: 190px;
  }
  @media ${device.desktop} {
    font-size: 340px;
  }
`;

const AboutMeDescription = styled.div`
  align-items: center;
  font-family: "AvenirLight";
  text-align: left;
  margin-left: 30%;
  margin-right: 5%;
  @media ${device.laptop} {
    transform: translateY(90%);
    font-size: 25x;
  }
  @media ${device.laptopL} {
    transform: translateY(87%);
    font-size: 30px;
  }
  @media ${device.desktop} {
    transform: translateY(80%);
    font-size: 40px;
  }
`;

class AboutMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPercent: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    const { body, documentElement } = event.srcElement;
    const sd = Math.max(body.scrollTop, documentElement.scrollTop);
    const sp =
      (sd / (documentElement.scrollHeight - documentElement.clientHeight)) *
      100;
    const maxlimit =
      (documentElement.clientHeight * 150) / documentElement.scrollHeight;
    if (sp >= 0 && sp <= maxlimit) {
      this.setState({ scrollPercent: sp });
    }
  }

  render() {
    const { scrollPercent } = this.state;
    return (
      <Container>
        <AboutMeTitle scrollPercent={scrollPercent}>ABOUT ME</AboutMeTitle>
        <AboutMeDescription>
          A passionate problem solver and software developer who loves creating
          projects that tackle real-world challenges. With a solid foundation in
          Java and a proven ability to quickly learn and adapt to new
          technologies, I thrive on building scalable and impactful solutions. I
          specialize in Full Stack Web Development and Mobile App Development,
          with expertise in Java, Flutter and MERN stack. My strong
          understanding of CS fundamentals and proficiency in object-oriented
          programming enable me to bridge the gap between frontend and backend
          development seamlessly.Beyond coding, I am deeply motivated by the
          opportunity to solve complex problems and deliver innovative,
          user-centric solutions. I am a fast learner who keeps pace with the
          latest industry trends, constantly striving to enhance my skills and
          make a meaningful impact through technology.
        </AboutMeDescription>
      </Container>
    );
  }
}

export default AboutMe;
