import React from 'react';

function loading(props) {
    return (
        <div>
            loading.
        </div>
    );
}

export default loading;