import React, { Component } from 'react';
import styled from 'styled-components';
import vhCheck from 'vh-check';
import TextContent from './TextContent';
import ImageContent from './ImageContent';

const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    //  border: 10px dashed red; 
`;

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vh: 0,
      slideNumber: 0,
    };
    this.pageSplitTimes = 1.3;
    this.lastScrollTop = 0;
    this.scrollDirectionDown = true;
    this.handleScroll = this.handleScroll.bind(this);
    this.workDetails = [
      {
        number: "",
        projectName: "",
        projectDesc: "",
        projectType: "",
        roles: [""],
      },
      {
        number: "01",
        projectName: "WALNUT",
        projectDesc:"Designed a Flutter app added Neumorphic design to the whole app.",
        projectType: "Mobile App",
        roles: ["Mobile App Developer"],
      },
      {
        number: "02",
        projectName: "Edustart",
        projectDesc:  "Developed a mobile app to simplify creating, distributing, and grading assignments at schools/educational institutions. The app also streamline the process of sharing files between teachers and students.",
        projectType: "Mobile App",
        roles: ["Front-end Developer", "UI Designer"],
      },
      // {
      //   number: "03",
      //   projectName: "ERPAM",
      //   projectDesc:"Designed a scalable REST API, front-end MVC architecture using MongoDB databases & dynamic Full-stack App for reducing documentation, data processing, redressing, task management, and report evaluation for the Department of Applied Mathematics of Delhi Technological University.",
      //   projectType: "WEB APP",
      //   roles: ["Full Stack Developer"],
      // },
      {
        number: "03",
        projectName: "Tesla app",
        projectDesc: "Created an app concept that controls tesla cars remotely",
        projectType: "App Design",
        roles: ["UI Design"],
      },
      // {
      //   number: "05",
      //   projectName: "Netflix Clone",
      //   projectDesc:
      //     "This project is a simplified front end clone of Netflix. It was created with React and CSS (Grid and Flexbox). It uses The MovieDB Api.",
      //   projectType: "WEB APP",
      //   roles: ["React App"],
      // },
      {
        number: "04",
        projectName: "More Projects",
        projectDesc:
          "Have an idea or an epic project in mind? Talk to me. Let’s work together and make something great...",
        projectType: "Github",
        roles: [""],
      },
      {
        number: "",
        projectName: "",
        projectDesc: "",
        projectType: "",
        roles: [""],
      },
      
    ];
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const vhDiff = vhCheck().offset;
    this.setState(
      {
        vh: Math.round(
          (window.document.documentElement.clientHeight + vhDiff) * this.pageSplitTimes,
        ),
      },
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    const { body, documentElement } = event.srcElement;
    const { vh, slideNumber } = this.state;
    const scrollDistance = Math.max(body.scrollTop, documentElement.scrollTop);
    if (scrollDistance > this.lastScrollTop) {
      this.scrollDirectionDown = true;
    } else {
      this.scrollDirectionDown = false;
    }
    this.lastScrollTop = scrollDistance;
    // console.log(scrollDistance);


    if (Math.floor(scrollDistance / vh) !== slideNumber
      && slideNumber < this.workDetails.length - 1) {
      this.setState({ slideNumber: Math.floor(scrollDistance / vh) });
    } else if (slideNumber === this.workDetails.length - 1
      && (Math.floor(scrollDistance / vh) < slideNumber)) {
      this.setState({ slideNumber: Math.floor(scrollDistance / vh) });
    }
  }

  changeTextContentBasedOnScroll() {
    const { slideNumber } = this.state;
    const refresh = true;
    return (
      <TextContent
        number={this.workDetails[slideNumber].number}
        projectName={this.workDetails[slideNumber].projectName}
        projectDesc={this.workDetails[slideNumber].projectDesc}
        projectType={this.workDetails[slideNumber].projectType}
        roles={this.workDetails[slideNumber].roles}
        refreshToggle={refresh}
      />
    );
  }

  render() {
    return (
      <Container>
        {this.changeTextContentBasedOnScroll()}
        <ImageContent pageSplitTimes={this.pageSplitTimes} />
      </Container>
    );
  }
}

export default Work;
