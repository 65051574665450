import React, { Component } from "react";
import styled from "styled-components";
import NameReveal from "./NameReveal";
import TitleReveal from "./TitleReveal";
// import "./button.css";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: white;
  /* border: 1px solid blue; */
`;

// const Button = styled.div`
//   width: 380px;
//   height: 86px;
//   font-size: 36px;
//   font-family: "Bebas Neue", cursive;
//   background: linear-gradient(45deg, transparent 5%, grey 5%);
//   // border: 0;
//   color: #ff013c;
//   // letter-spacing: 3px;
//   // line-height: 88px;
//   outline: transparent;
// `;

class NameAndJobTitle extends Component {
  render() {
    return (
      <Container>
        <NameReveal text="Satyam Jaiswal" fontFam="Valencia" timeDelay={500} />
        <br />
        <TitleReveal
          text="Software Engineer | Full-stack developer | Building Scalable Web & Mobile Solutions"
          fontFam="AvenirRoman"
          timeDelay={1300}
        />
        <br />

        {/* <Button> Download CV</Button> */}
        {/* <div>
          <button
            style={{
              border: "2px solid black",
              "background-color": white,
              color: black,
              padding: "14px 28px",
              "font-size": "16px",
              cursor: pointer,
            }}
          >
            Download CV
          </button>
        </div> */}
      </Container>
    );
  }
}

export default NameAndJobTitle;
