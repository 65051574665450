import React, { Component } from "react";
import MobNameAndJobTitle from "../HeroSlide/NameAndJobTitle";
import MobAboutMe from "../HeroSlide/AboutMe";

class Hero extends Component {
  render() {
    return (
      <React.Fragment>
        <MobNameAndJobTitle />
        <MobAboutMe />
      </React.Fragment>
    );
  }
}

export default Hero;
