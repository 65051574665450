import React, { Component } from "react";
import styled from "styled-components";
import TextContent from "./TextContent";
import ImageContent from "./ImageContent";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  // border: 1px dashed red;
`;

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vh: 0,
      slideNumber: 0,
    };
    this.pageSplitTimes = 1.5;
    this.lastScrollTop = 0;
    this.scrollDirectionDown = true;
    this.handleScroll = this.handleScroll.bind(this);
    this.workDetails = [
      {
        number: "",
        projectName: "",
        projectDesc: "",
        projectType: "",
        roles: [""],
        url: "#",
      },
      {
        number: "01",
        projectName: "WALNUT",
        projectDesc:
          "Designed a Flutter app added Neumorphic design to the whole app.",
        projectType: "Mobile App",
        roles: ["Mobile App Developer"],
        url: "https://play.google.com/store/apps/details?id=com.walnut.walnut_app",
      },
      {
        number: "02",
        projectName: "Edustart",
        projectDesc:
          "Developed a mobile app to simplify creating, distributing, and grading assignments at schools/educational institutions. The app also streamline the process of sharing files between teachers and students.",
        projectType: "Mobile App",
        roles: ["Front-end Developer", "UI Designer"],
        url: "https://github.com/Satyam-Jaiswal/Edustart",
      },
      {
        number: "03",
        projectName: "ERPAM",
        projectDesc:
          "Designed a scalable REST API, front-end MVC architecture using MongoDB databases & dynamic Full-stack App for reducing documentation, data processing, redressing, task management, and report evaluation for the Department of Applied Mathematics of Delhi Technological University.",
        projectType: "WEB APP",
        roles: ["Full Stack Developer"],
        url: "#",
      },
      {
        number: "04",
        projectName: "Tesla app",
        projectDesc: "Created an app concept that controls tesla cars remotely",
        projectType: "App Design",
        roles: ["UI Design"],
        url: "#",
      },
      {
        number: "05",
        projectName: "Netflix Clone",
        projectDesc:
          "This project is a simplified front end clone of Netflix. It was created with React and CSS (Grid and Flexbox). It uses The MovieDB Api.",
        projectType: "WEB APP",
        roles: ["React App"],
        url: "https://netflix-clone-by-satyam.netlify.app/",
      },
      {
        number: "06",
        projectName: "More Projects",
        projectDesc:
          "Have an idea or an epic project in mind? Talk to me. Let’s work together and make something great...",
        projectType: "Github",
        roles: [""],
        url: "https://github.com/Satyam-Jaiswal",
      },
      {
        number: "",
        projectName: "",
        projectDesc: "",
        projectType: "",
        roles: [""],
        url: "#",
      },
    ];
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.setState({
      vh: Math.round(
        window.document.documentElement.clientHeight * this.pageSplitTimes
      ),
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    const { body, documentElement } = event.srcElement;
    const { vh, slideNumber } = this.state;
    const scrollDistance = Math.max(body.scrollTop, documentElement.scrollTop);
    if (scrollDistance > this.lastScrollTop) {
      this.scrollDirectionDown = true;
    } else {
      this.scrollDirectionDown = false;
    }
    this.lastScrollTop = scrollDistance;
    // console.log(scrollDistance);

    if (
      Math.floor(scrollDistance / vh) !== slideNumber &&
      slideNumber < this.workDetails.length - 1
    ) {
      this.setState({ slideNumber: Math.floor(scrollDistance / vh) });
    } else if (
      slideNumber === this.workDetails.length - 1 &&
      Math.floor(scrollDistance / vh) < slideNumber
    ) {
      this.setState({ slideNumber: Math.floor(scrollDistance / vh) });
    }
  }

  changeTextContentBasedOnScroll() {
    const { slideNumber } = this.state;
    const refresh = true;
    return (
      <TextContent
        number={this.workDetails[slideNumber].number}
        projectName={this.workDetails[slideNumber].projectName}
        projectDesc={this.workDetails[slideNumber].projectDesc}
        projectType={this.workDetails[slideNumber].projectType}
        roles={this.workDetails[slideNumber].roles}
        url={this.workDetails[slideNumber].url}
        refreshToggle={refresh}
      />
    );
  }

  render() {
    return (
      <Container>
        {this.changeTextContentBasedOnScroll()}
        <ImageContent pageSplitTimes={this.pageSplitTimes} />
      </Container>
    );
  }
}

export default Work;
